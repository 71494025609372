import React from 'react';
import { SectionContainer } from 'components/grid';
import SearchForm from 'components/SearchForm';
import { BUTTON_CTA_STYLE, CTA_CLICK } from 'analytics/constants';
import { styled } from '@mui/material/styles';
import { Typography } from '@tkww/the-bash-frontend';
import { Paper, Box } from '@mui/material';

import JumpToSections from 'views/Homepage/Hero/JumpToSections';
import HeadingWithImage from './HeadingWithImage';

const StyledHero = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  position: 'relative',
  width: '100%',
}));

const StyledColorLayer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.green[50],
}));

const StyledTop = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.blue[700],
  paddingBottom: 105,
  position: 'relative',
  overflow: 'hidden',
}));

const StyledTopCurve = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.blue[700],
  borderBottomLeftRadius: '50% 100%',
  borderBottomRightRadius: '50% 100%',
  height: '48px',
  width: '100%',
  marginBottom: '-90px',
}));

const StyledBottomCurve = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.green[50],
  borderBottomLeftRadius: '50% 100%',
  borderBottomRightRadius: '50% 100%',
  height: '48px',
  width: '100%',
  bottom: 0,
}));

const HomepageHero = () => {
  const onSearchTrackingData = {
    name: CTA_CLICK,
    properties: {
      clickArea: 'Search Bar',
      clickTarget: 'Search',
      ctaStyle: BUTTON_CTA_STYLE,
    },
  };

  return (
    <StyledHero>
      <StyledColorLayer>
        <StyledTop>
          <HeadingWithImage />
        </StyledTop>
        <StyledTopCurve />
        <SectionContainer backgroundColor="transparent">
          <Paper
            elevation={0}
            sx={{
              padding: '4px 40px 32px 40px',
            }}
          >
            <Typography variant="h2">Looking for a vendor or entertainer?</Typography>
            <SearchForm
              showEventDateField={false}
              enableGeoLocation
              onSearchTrackingData={onSearchTrackingData}
            />
          </Paper>
          <JumpToSections />
        </SectionContainer>
      </StyledColorLayer>
      <StyledBottomCurve />
    </StyledHero>
  );
};

export default HomepageHero;
