import React from 'react';
import { HOMEPAGE_NAME } from 'constants/pageNames';
import PlannerValueProposition from 'components/PlannerValueProposition';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import HomepageHero from 'views/Homepage/Hero';
import EventSection from 'views/Homepage/EventSection';
import ArticleSection from 'views/Homepage/ArticleSection';
import HomePageServiceTiles from 'components/HomePageServiceTiles';
import TrustSection from 'components/Trust';
import SignupSection from 'views/Homepage/SignupSection';

const StyledBottomBackground = styled(Box)(({ theme }) => ({
  backgroundImage: "url('images-next/trust-section/trust-section-background-mobile.svg')",
  [theme.breakpoints.up('md')]: {
    backgroundImage: "url('images-next/trust-section/trust-section-background-tablet.svg')",
  },
  [theme.breakpoints.up('lg')]: {
    backgroundImage: "url('images-next/trust-section/trust-section-background-desktop.svg')",
  },
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top center',
}));

const HomepageView = (props) => {
  const { articles, events, userAgent, trustFacts } = props;

  return (
    <>
      <HomepageHero />
      <PlannerValueProposition
        segmentProps={{ name: HOMEPAGE_NAME }}
        sx={(theme) => ({
          marginBottom: '32px',
          [theme.breakpoints.up('md')]: {
            marginBottom: '60px',
          },
        })}
      />
      <HomePageServiceTiles />
      <StyledBottomBackground>
        <TrustSection
          userAgent={userAgent}
          trustFacts={trustFacts}
          title="Trust Us With Your Special Moments"
          subtitle="Since 1997, we’ve helped party hosts & planners bring their events to life."
        />
        <EventSection events={events} />
        <ArticleSection articles={articles} />
        <SignupSection />
      </StyledBottomBackground>
    </>
  );
};

HomepageView.propTypes = {
  trustFacts: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
    })
  ),
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      metaTitle: PropTypes.string.isRequired,
      hed: PropTypes.string.isRequired,
      thumbSquareUrl: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    })
  ).isRequired,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      homepageTileCopy: PropTypes.string.isRequired,
      homepageTileImage: PropTypes.string.isRequired,
      eventFileName: PropTypes.string.isRequired,
      userAgent: PropTypes.string,
    }).isRequired
  ),
};

HomepageView.defaultProps = {
  trustFacts: [
    {
      title: '500,000+',
      body: 'Events Booked',
    },
    {
      title: '210,000+',
      body: 'Verified reviews and ratings',
    },
    {
      title: '25+ years',
      body: 'Industry expertise and knowledge',
    },
  ],

  articles: [],
  events: [],
};

export default HomepageView;
