import React from 'react';
import { Typography } from '@tkww/the-bash-frontend';
import Grid from '@mui/material/Grid';
import ServiceTile from 'components/ServiceTile';
import SectionContainer from 'components/grid/SectionContainer';
import { styled } from '@mui/material/styles';
import { SERVICE_TILE_DATA } from 'constants/Homepage';

const StyledSectionContainer = styled(SectionContainer)(({ theme }) => ({
  marginTop: 16,
  marginBottom: 72,
  [theme.breakpoints.up('md')]: {
    marginBottom: 100,
  },
}));

const HeadingTypography = styled(Typography)(() => ({
  textAlign: 'center',
  margin: '0px 0px 4px 0px',
}));

const SubHeadingTypography = styled(Typography)(() => ({
  textAlign: 'center',
  marginBottom: 32,
}));

export const HomePageServiceTiles = () => (
  <StyledSectionContainer id="service-tiles">
    <HeadingTypography variant="h2">Explore Our Marketplace</HeadingTypography>
    <SubHeadingTypography variant="body1">
      Find vendors & entertainers nationwide that best fit your event and budget.
    </SubHeadingTypography>
    <Grid container>
      {SERVICE_TILE_DATA.map((serviceSection, index) => (
        <Grid item xs={12} md={6} lg={4} key={serviceSection.pluralForm}>
          <ServiceTile serviceData={serviceSection} index={index} />
        </Grid>
      ))}
    </Grid>
  </StyledSectionContainer>
);

HomePageServiceTiles.propTypes = {};

HomePageServiceTiles.defaultProps = {};

export default HomePageServiceTiles;
